import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  };

  
  return (
    <React.Fragment>
      <Container fluid className='px-4 px-lg-5 py-3  border-top footer'>
        <Row className='py-2 justify-content-md-center'>
          <Col lg="4" className='order-1'>
            <h5>Want to be the first to know?
              <span className="d-block">Follow us on social</span>
            </h5>
            <a href="/" className='pe-1'> <FaInstagram className='fs-4' /></a>
     
        

          </Col>


          <Col lg="4" className='text-center order-3 order-lg-2'>
            
            <p className='text-center'>Copyright © 2023. Bharat Furnishings. All rights reserved.</p>

            <p className='text-center'><span className="d-block p-0 lh-sm">Designed & Maintained by </span>
              Goradia Infotech
            </p>
          </Col>


          <Col lg="4" className='order-2 order-lg-3' >
            <Row md={12}>
              <Col>
                <ul>
                  <li><NavLink to="/About" onClick={scrollToTop}>About</NavLink></li>
                  <li><NavLink to="/Fabric" onClick={scrollToTop}>Fabric</NavLink></li>
                  <li><NavLink to="/Wallpaper" onClick={scrollToTop}>Wallpaper</NavLink></li>
                  <li><NavLink to="/Bed-Bath-and-Linen" onClick={scrollToTop}>Bed & Bath Linen</NavLink></li>
                  <li><NavLink to="/Blinds" onClick={scrollToTop}>Blinds</NavLink></li>

                </ul>
              </Col>

              <Col>
                <ul>
                  <li><NavLink to="/Rugs" onClick={scrollToTop}>Rugs</NavLink></li>
                  <li><NavLink to="/Contact" onClick={scrollToTop} >Contact</NavLink></li>
                  <li><NavLink to="/Sitemap" onClick={scrollToTop}>Sitemap</NavLink></li>
                  <li><NavLink to="/Privacy-Policy" onClick={scrollToTop}> Privacy Policy</NavLink></li>
                </ul>
              </Col>

            </Row>






          </Col>

        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Footer
