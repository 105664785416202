import React, { useEffect } from 'react';
import ContactImg from "../../images/contact-img.jpg";
import darkBanner from "../../images/dark-img.jpg";
import { Col, Container, Row } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { LiaPhoneSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';


const Contact = () => {

    useEffect(() => {
        document.title = "Contact - bharat furnishings";
    }, []);

    return (

        <React.Fragment>
            <Container fluid className='px-4 px-lg-5'>
                <Row className='d-flex align-items-center'>
                    <Col lg="12" className='custom_slider inner_banner position-relative mb-5'>

                        <img src={darkBanner} alt="Contact" className='img-fluid w-100' />

                        <div className="position-absolute top-50 start-50 translate-middle ">
                            <h1 className="text-white text-center">Contact </h1>
    
                           </div>

                    </Col>




                    <Col md="5">
                        <img src={ContactImg} alt="" className='img-fluid' />
                    </Col>


                    <Col lg="7">
                    <Row>
                    <Col md="6">
                    <div className="text-center pb-1 mb-4">
                            <h3 className="mb-1 fw-bold"> Mumbai - Kemp's Corner</h3>
                            <p className="mb-1 text-center lh-sm">India House, Cumballa Hill, Kemps Corner,
                                <span className="d-block"> Mumbai - 400036. Maharashtra. India.</span>
                            </p>

                            <p className=" text-center">
                                <Link to="https://wa.link/jv2ebi">
                                 <FaWhatsapp className='text-success' />
                                 +91 7977866015 </Link>&nbsp; &nbsp;  <LiaPhoneSolid /> 
                                 <Link to="tel:+91 22 61456050">+91 22 61456050</Link>
                            </p>

                   <Link to="https://maps.app.goo.gl/g5DkGXrsEWjrfH4w8">
                    <button className='btn btn-outline-dark fs-5'>View Direction</button>
                    </Link> 
                 
                        </div>

                        <div className="text-center pb-1 mb-4">
                            <h3 className="mb-1 fw-bold"> Mumbai - Vile Parle</h3>
                            <p className=" mb-1 text-center lh-sm">Crystal Classic, S. V. Road, Next to Golden Tabacco, Vile Parle (W),
                                <span className="d-block"> Mumbai-400 058. Maharashtra. India.</span>
                            </p>
                            <p className=" text-center">
                                <Link to="https://wa.link/p6csbt">
                                <FaWhatsapp className='text-success'/> +91 9867360509</Link> &nbsp; &nbsp;
                                <LiaPhoneSolid /> 
                                <Link to="tel: +91 22 66804545">+91 22 66804545 </Link> 
                                </p>
                    
                            <Link to="https://maps.app.goo.gl/mwEhBmspHWgsXDwNA">
                            <button className='btn btn-outline-dark fs-5'>View Direction</button>
                            </Link> 
                           
                        </div>

                        <div className="text-center pb-1 mb-4">
                            <h3 className="mb-1 fw-bold"> Thane</h3>
                            <p className=" mb-1 text-center lh-sm">
                                Sun Magnetica, Service Road, Lousie Wadi, Near RTO,
                                 Off Eastern Express Highway,<br/> Thane (west) - 400 406
                            </p>
                            <p className="text-center"><Link to="https://wa.link/4w4bhz"><FaWhatsapp className='text-success' /> +91 8369059660</Link> 
                            &nbsp; &nbsp;
                                <LiaPhoneSolid /> 
                               <Link to='tel: +91 22 25806050'> +91 22 25806050</Link>
                            </p>

                            
                            <Link to="https://maps.app.goo.gl/7STdRHi9Yza2iK126">
                            <button className='btn btn-outline-dark fs-5'>View Direction</button>
                            </Link> 
                      
                        </div>
                    
                   

                    </Col>


                    <Col md="6">

                        <div className="text-center pb-1 mb-4">
                            <h3 className="mb-1 fw-bold"> Pune</h3>
                            <p className=" mb-1 text-center lh-sm">Sai-Ikon, Survey 84/2, 10, BanerRoad, Near Sakal Nagar,
                                <span className="d-block"> Pune, Maharashtra. India.</span>
                            </p>
                            <p className=" text-center">
                                <Link to="https://wa.link/hsybgw"><FaWhatsapp className='text-success' /> +91 8237057705</Link> &nbsp; &nbsp;
                                <LiaPhoneSolid />
                                <Link to="tel: +91 22 66804545"> +91 22 66804545</Link>
                                 </p>

                                 <Link to="https://maps.app.goo.gl/p5aPBt6YhUZ2XUTn6">
                            <button className='btn btn-outline-dark fs-5'>View Direction</button>
                            </Link> 
                       

                        </div>
                      
                        <div className="text-center pb-1 mb-4">
                            <h3 className="mb-1 fw-bold"> Surat</h3>
                            <p className=" mb-1  text-center lh-sm">
                            Shop No. 5, Union Heights, Maharana Pratap Road, 
                            <span className="d-block">Piplod, Surat, Gujarat - 395007. </span>
                            </p>

                            <p className=" text-center"> <Link to="https://wa.link/rpqqqm">
                                <FaWhatsapp className='text-success' /> +91 7203017427</Link>
                                
                                &nbsp; &nbsp;
                                <LiaPhoneSolid /> <Link to="tel: +91 7048004463">+91 7048004463</Link>
                                </p>

                                <Link to="https://maps.app.goo.gl/BAPBLM2vs2DKfTaC6">
                            <button className='btn btn-outline-dark fs-5'>View Direction</button>
                            </Link> 
                        </div>

                        <div className="text-center mb-4">
                            <h3 className="mb-1 fw-bold"> Ahmedabad</h3>
                            <p className=" mb-1 text-center lh-sm">Sarthik II, Opp Rajpath Club, Sarkhej Gandhi Nagar H/W,
                                <span className="d-block"> Ahmedabad - 380015. Gujarat, India.</span>
                            </p>
                            <p className="text-center"> 
                            <Link to="https://wa.link/hlpjad">
                                <FaWhatsapp className='text-success' /> +91 8799156420</Link> &nbsp;&nbsp;
                                <LiaPhoneSolid /> 
                                <Link to="tel: +91 79 26872027"> +91 79 26872027 / 28</Link>
                            </p>
                            
                            <Link to="https://maps.app.goo.gl/QDGzfGzKfpfLbaicA">
                            <button className='btn btn-outline-dark fs-5'>View Direction</button>
                            </Link> 
                        </div>


                    </Col>



                    </Row>

                   </Col>
                </Row>
            </Container>




        </React.Fragment>
    )
}

export default Contact
